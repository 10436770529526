import {
  put, call, all,
  takeLatest, takeEvery,
} from 'redux-saga/effects';
import {
  REQUEST_LIST_INIT,
  REQUEST_SEARCH_INIT,
  ADD_REQUEST_INIT,
  UPDATE_REQUEST_DETAILS_INIT,
  REASSIGN_REQUEST_INIT,
  UPDATE_STATUS_INIT,
  SEND_ITINERARY_INIT,
  // SUBMIT_ITINERARY_DETAILS_INIT,
  DELETE_ITINERARY_INIT,
  CREATE_UPDATE_ITINERARY,
  GENERATE_VOUCHER_INIT,
  REMARK_UPDATE_INIT,
  GET_REQUEST_FILTERS_INIT,
  STAR_REQUEST_INIT,
  UPDATE_ORDER_INIT,
  MODIFY_INSTALLMENTS_INIT,
  BOOKING_ACTION_INIT,
  ACCEPT_PAYMENT_INIT,
  GET_REQUEST_BY_ID_INIT,
  RESEND_CONFIRMATION_INIT,
  REFUND_PAYMENT_INIT,
  CANCEL_ITINERARY_INIT,
  REFUND_FROM_WALLET_INIT,
  REFUND_TO_WALLET_INIT,
  MARK_BOOKING_TRANSACTION_INIT,
  CANCEL_BOOKING_INIT,
  GET_BOOKINGS_INIT,
  GET_B2B_WALLET_BALANCE_INIT,
  GET_REFUND_DATA_INIT,
  GET_VIRTUAL_ACCOUNT_DATA_INIT,
  INTERESTED_REQUEST_INIT,
  SAVE_AS_TEMPLATE_ITINERARY_INIT,
} from '../../actionTypes';
import {
  requestListSuccess,
  requestListFailure,
  requestSearchSuccess,
  requestSearchFailure,
  addRequestSuccess,
  addRequestFailure,
  updateRequestDetailsSuccess,
  updateRequestDetailsFailure,
  reAssignRequestSuccess,
  reAssignRequestFailure,
  updateStatusSuccess,
  updateStatusFailure,
  // submitItineraryDetailsSuccess,
  // submitItineraryDetailsFailure,
  // sendItinerarySuccess,
  // sendItineraryFailure,
  deleteItinerarySuccess,
  deleteItineraryFailure,
  remarkUpdateSuccess,
  remarkUpdateFailure,
  getRequestFiltersSuccess,
  getRequestFiltersFailure,
  starRequestSuccess,
  starRequestFailure,
  interestedRequestSuccess,
  interestedRequestFailure,
  updateOrderSuccess,
  updateOrderFailure,
  modifyInstallmentsSuccess,
  modifyInstallmentsFailure,
  bookingActionSuccess,
  bookingActionFailure,
  acceptPaymentSuccess,
  acceptPaymentFailure,
  getRequestByIDSuccess,
  getRequestByIDFailure,
  resendConfirmationSuccess,
  resendConfirmationFailure,
  generateVoucherSuccess,
  generateVoucherFailure,
  refundPaymentSuccess,
  refundPaymentFailure,
  cancelItinerarySuccess,
  cancelItineraryFailure,
  refundFromWalletSuccess,
  refundFromWalletFailure,
  refundToWalletSuccess,
  refundToWalletFailure,
  markBookingTransactionSuccess,
  markBookingTransactionFailure,
  cancelBookingSuccess,
  cancelBookingFailure,
  getBookingsSuccess,
  getBookingsFailure,
  getB2bWalletBalanceSuccess,
  getB2bWalletBalanceFailure,
  getRefundDataSuccess,
  getRefundDataFailure,
  getVirtualAccountDataSuccess,
  getVirtualAccountDataFailure,
  saveAsTemplateItinerarySuccess,
  saveAsTemplateItineraryFailure,
} from './dashboardActions';
import { baseURL, endPoints } from '../../utils/apiEndpoints';
import {
  postRequest,
  putRequest,
  getRequest,
} from '../../utils/apiRequests';
import {
  addFilesToUpload,
  mapImageToFile,
  getFileExtension,
  addTravellerFiles,
  mapTravellerFiles,
} from '../../utils/common';

function* requestList(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.getRequest}`;
    const { kind } = params;
    delete params.kind;
    const data = yield call(postRequest, url, params, headers);
    if (data.state) {
      yield put(requestListSuccess({
        ...data,
        kind,
      }));
    } else {
      yield put(requestListFailure(data.message));
    }
  } catch (err) {
    yield put(requestListFailure(err.message));
  }
}

export function* requestListSaga() {
  yield takeLatest(REQUEST_LIST_INIT, requestList);
}

function* requestSearch(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.searchRequest}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.state) {
      yield put(requestSearchSuccess(data));
    } else {
      yield put(requestSearchFailure(data.message));
    }
  } catch (err) {
    yield put(requestSearchFailure(err.message));
  }
}

export function* requestSearchSaga() {
  yield takeLatest(REQUEST_SEARCH_INIT, requestSearch);
}

function* addRequest(action) {
  try {
    const {
      params: { params, isReAssigning },
      headers,
    } = action.payload;
    const url = `${baseURL}${endPoints.addNewRequest}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.state) {
      yield put(addRequestSuccess({
        ...data,
        isReAssigning,
      }));
    } else {
      yield put(addRequestFailure(data.message || 'Error adding request'));
    }
  } catch (err) {
    yield put(addRequestFailure(err.message));
  }
}

export function* addRequestSaga() {
  yield takeLatest(ADD_REQUEST_INIT, addRequest);
}

function* updateRequestDetails(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.updateRequest}`;
    const data = yield call(postRequest, url, params, headers);
    if (data.state) {
      yield put(updateRequestDetailsSuccess(data));
    } else {
      yield put(updateRequestDetailsFailure(data.message));
    }
  } catch (err) {
    yield put(updateRequestDetailsFailure(err.message));
  }
}

export function* updateRequestDetailsSaga() {
  yield takeLatest(UPDATE_REQUEST_DETAILS_INIT, updateRequestDetails);
}

function* reAssignRequest(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.assign}`;
    const data = yield call(postRequest, url, params, headers);
    if (!data.error) {
      yield put(reAssignRequestSuccess(data));
    } else {
      yield put(reAssignRequestFailure(data.message));
    }
  } catch (err) {
    yield put(reAssignRequestFailure(err.message));
  }
}

export function* reAssignRequestSaga() {
  yield takeLatest(REASSIGN_REQUEST_INIT, reAssignRequest);
}

function* updateStatus(action) {
  try {
    const { params, headers } = action.payload;
    if (params.action === 'UPDATE_ITINERARY' || params.action === 'REQUEST_BOOKING'
      || params.action === 'MODIFY_BOOKING_TECH' || params.action === 'MODIFY_BOOKING'
      || params.action === 'SEND_ITINERARY' || params.action === 'RE_SYNC_REQUEST') {
      yield put({
        type: CREATE_UPDATE_ITINERARY,
        payload: {
          params,
          headers,
        },
      });
      return;
    }
    const url = `${baseURL}${endPoints.updateStatus}`;
    const { skip } = params;
    delete params.skip;
    const data = yield call(postRequest, url, params, headers);
    if (data.state) {
      // yield put(updateStatusSuccess(data);
      yield put(updateStatusSuccess({
        ...data,
        requestAction: params.action,
        skip,
      }));
    } else {
      yield put(updateStatusFailure(data.message));
    }
  } catch (err) {
    yield put(updateStatusFailure(err.message));
  }
}

export function* updateStatusSaga() {
  yield takeLatest(UPDATE_STATUS_INIT, updateStatus);
}

function* sendItinerary(action) {
  try {
    const { params, headers } = action.payload;
    let fParams = null;
    let errorMsg = '';
    if (params.link) {
      fParams = {
        ...params.updateParams,
        details: {
          itineraryId: params.itineraryId,
          link: params.link,
          fileName: params.fileName,
        },
      };
    } else {
      const url = `${baseURL}${endPoints.uploadDocs}`;
      const data = yield call(postRequest, url, params.signedParams, headers);
      if (data.state) {
        // const formData = new FormData();
        // formData.append('file', params.file.file);
        const uploadResp = yield call(putRequest, data.urls[0].signedUrl, params.file.file, { 'Content-Type': 'multipart/form-data' });
        if (uploadResp.state) {
          fParams = {
            ...params.updateParams,
            details: {
              itineraryId: params.itineraryId,
              document: data.urls[0].document,
            },
          };
        } else {
          errorMsg = 'Error uploading docs to server';
        }
      } else {
        errorMsg = 'Error uploading docs to server';
      }
    }
    if (fParams) {
      const url = `${baseURL}${endPoints.updateStatus}`;
      const data = yield call(postRequest, url, fParams, headers);
      if (data.state) {
        yield put(updateStatusSuccess(data));
      } else {
        yield put(updateStatusFailure(data.message));
      }
    } else {
      yield put(updateStatusFailure(errorMsg));
    }
  } catch (err) {
    yield put(updateStatusFailure('Error uploading docs to server'));
  }
}

export function* sendItinerarySaga() {
  yield takeLatest(SEND_ITINERARY_INIT, sendItinerary);
}

// function* submitItineraryDetails(action) {
//   try {
//     const { params, headers } = action.payload;
//     const url = `${baseURL}${endPoints.signin}`;
//     const data = yield call(postRequest, url, params, headers);
//     yield put(submitItineraryDetailsSuccess(data));
//   } catch (err) {
//     yield put(submitItineraryDetailsFailure(err));
//   }
// }
//
// export function* submitItineraryDetailsSaga() {
//   yield takeLatest(SUBMIT_ITINERARY_DETAILS_INIT, submitItineraryDetails);
// }

function* deleteItinerary(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.deleteItinerary}`;
    const paramsToSend = { ...params };
    delete paramsToSend.requestType;
    const data = yield call(postRequest, url, paramsToSend, headers);
    if (data.state) {
      yield put(deleteItinerarySuccess({ ...data, ...params }));
    } else {
      yield put(deleteItineraryFailure(data.message));
    }
  } catch (err) {
    yield put(deleteItineraryFailure(err));
  }
}

export function* deleteItinerarySaga() {
  yield takeLatest(DELETE_ITINERARY_INIT, deleteItinerary);
}

function* saveAsTemplateItinerary(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.itineraryToggleTemplate.replace('ITINERARY_ID', params.itineraryId)}`;
    const paramsToSend = { ...params };
    const data = yield call(putRequest, url, paramsToSend, headers);
    if (data.itinerary) {
      yield put(saveAsTemplateItinerarySuccess(data));
    } else {
      yield put(saveAsTemplateItineraryFailure(data.message));
    }
  } catch (err) {
    yield put(saveAsTemplateItineraryFailure(err));
  }
}

export function* saveAsTemplateItinerarySaga() {
  yield takeLatest(SAVE_AS_TEMPLATE_ITINERARY_INIT, saveAsTemplateItinerary);
}

function* generateVoucher(action) {
  try {
    const { params, headers } = action.payload;
    let uploadFiles = [];
    let partFiles = {};
    const parts = params.voucherDetails.customerItineraryParts;
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].type === 'FLIGHT') {
        const modData = addFilesToUpload(i, parts[i].flightData.documents, uploadFiles, partFiles);
        partFiles = modData.uploadMap;
        uploadFiles = modData.uploadArr;
      }
    }
    for (let i = 0; i < params.voucherDetails.otherDocuments.length; i++) {
      const modData = addFilesToUpload(i, [params.voucherDetails.otherDocuments[i].document], uploadFiles, partFiles);
      partFiles = modData.uploadMap;
      uploadFiles = modData.uploadArr;
    }
    const data = addTravellerFiles(params.voucherDetails.travellerDetails, uploadFiles, partFiles);
    uploadFiles = data.uploadArr;
    partFiles = data.uploadMap;
    const linkUrl = `${baseURL}${endPoints.uploadDocs}`;
    const imageUploadLinkResp = yield call(postRequest, linkUrl, { files: uploadFiles }, headers);
    if (!imageUploadLinkResp.error) {
      const uploadResp = yield all(imageUploadLinkResp.urls.map(({
        signedUrl, fileName, document,
      }) => {
        // const formData = new FormData();
        // formData.append('file', partFiles[fileName].file);
        partFiles[fileName].document = document;
        return call(putRequest, signedUrl, partFiles[fileName].file, {});
      }));
      let state = true;
      if (uploadResp) {
        for (let i = 0; i < uploadResp.length; i++) {
          if (uploadResp[i].state !== true) {
            state = false;
            break;
          }
        }
      }
      if (state) {
        params.voucherDetails.travellerDetails = mapTravellerFiles(
          params.voucherDetails.travellerDetails, partFiles
        );
        // mapping customer itinerary part documents
        for (let i = 0; i < params.voucherDetails.customerItineraryParts.length; i++) {
          if (params.voucherDetails.customerItineraryParts[i].type === 'FLIGHT') {
            if (params.voucherDetails.customerItineraryParts[i].flightData.documents?.length) {
              params.voucherDetails.customerItineraryParts[i].flightData.documents = mapImageToFile(
                params.voucherDetails.customerItineraryParts[i].flightData.documents, partFiles
              );
            }
          }
        }
        for (let i = 0; i < params.voucherDetails.otherDocuments.length; i++) {
          const doc = params.voucherDetails.otherDocuments[i].document;
          const { document, _id } = partFiles[doc.name || doc.fileName];
          if (_id) {
            params.voucherDetails.otherDocuments[i].document = _id;
          } else {
            params.voucherDetails.otherDocuments[i].document = document;
          }
        }
        const url = `${baseURL}${endPoints.generateVoucher}`;
        const { updateKey } = params;
        delete params.updateKey;
        const response = yield call(postRequest, url, params, headers);
        if (!response.error) {
          yield put(generateVoucherSuccess({
            ...response,
            updateKey,
          }));
        } else {
          yield put(generateVoucherFailure(response.message));
        }
      } else {
        yield put(generateVoucherFailure('Error uploading documents'));
      }
    } else {
      yield put(generateVoucherFailure('Error getting upload links'));
    }
  } catch (err) {
    yield put(generateVoucherFailure(err.message));
  }
}

export function* generateVoucherSaga() {
  yield takeLatest(GENERATE_VOUCHER_INIT, generateVoucher);
}

function* createUpdateItinerary(action) {
  try {
    const { params, headers } = action.payload;
    let uploadFiles = [];
    let partFiles = {};
    const parts = params.details.itineraryParts;
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].type === 'ACTIVITY') {
        const modData = addFilesToUpload(i, parts[i].activityData.images, uploadFiles, partFiles);
        partFiles = modData.uploadMap;
        uploadFiles = modData.uploadArr;
      }
      if (parts[i].type === 'FLIGHT') {
        const modData = addFilesToUpload(i, parts[i].flightData.documents, uploadFiles, partFiles);
        partFiles = modData.uploadMap;
        uploadFiles = modData.uploadArr;
      }
      if (parts[i].type === 'SERVICE' && parts[i].serviceData.serviceType === 'Travel Insurance') {
        const modData = addFilesToUpload(i, parts[i].serviceData.travelInsuranceData.documents,
          uploadFiles, partFiles);
        partFiles = modData.uploadMap;
        uploadFiles = modData.uploadArr;
      }
    }

    // uploading user documents as well
    const modData = addFilesToUpload(-1, params.details.documents, uploadFiles, partFiles);
    partFiles = modData.uploadMap;
    uploadFiles = modData.uploadArr;

    // checking if itinerary data was changed
    let hasItineraryLink = false;
    if (params.details.itineraryData?.modified) {
      if (params.details.itineraryData.link) {
        params.details.link = params.details.itineraryData.link;
        delete params.details.itineraryData;
      } else {
        const data = addFilesToUpload(-1, [params.details.itineraryData.file],
          uploadFiles, partFiles);
        partFiles = data.uploadMap;
        uploadFiles = data.uploadArr;
        hasItineraryLink = true;
      }
    } else if (params.details.itineraryData?._id) {
      params.details.document = params.details.itineraryData._id;
      delete params.details.itineraryData;
    } else if (params.details.itineraryData) {
      params.details.link = params.details.itineraryData.link;
      delete params.details.itineraryData;
    }
    const linkUrl = `${baseURL}${endPoints.uploadDocs}`;
    const imageUploadLinkResp = yield call(postRequest, linkUrl, { files: uploadFiles }, headers);
    if (!imageUploadLinkResp.error) {
      const uploadResp = yield all(imageUploadLinkResp.urls.map(({
        signedUrl, fileName, document,
      }) => {
        // const formData = new FormData();
        // formData.append('file', partFiles[fileName].file);
        partFiles[fileName].document = document;
        return call(putRequest, signedUrl, partFiles[fileName].file, {});
      }));
      let state = true;
      if (uploadResp) {
        for (let i = 0; i < uploadResp.length; i++) {
          if (uploadResp[i].state !== true) {
            state = false;
            break;
          }
        }
      }
      if (state) {
        // mapping user documents
        params.details.documents = mapImageToFile(params.details.documents, partFiles);
        if (hasItineraryLink) {
          const itnData = mapImageToFile([params.details.itineraryData.file], partFiles);
          params.details.document = itnData[0];
          delete params.details.itineraryData;
        }

        // mapping itinerary part documents
        for (let i = 0; i < params.details.itineraryParts.length; i++) {
          if (params.details.itineraryParts[i].type === 'ACTIVITY') {
            if (params.details.itineraryParts[i].activityData.images?.length) {
              params.details.itineraryParts[i].activityData.images = mapImageToFile(
                params.details.itineraryParts[i].activityData.images, partFiles
              );
            }
          }
          if (params.details.itineraryParts[i].type === 'FLIGHT') {
            if (params.details.itineraryParts[i].flightData.documents?.length) {
              params.details.itineraryParts[i].flightData.documents = mapImageToFile(
                params.details.itineraryParts[i].flightData.documents, partFiles
              );
            }
          }
          if (params.details.itineraryParts[i].type === 'SERVICE'
            && params.details.itineraryParts[i].serviceData.serviceType === 'Travel Insurance') {
            params.details.itineraryParts[i]
              .serviceData.travelInsuranceData.documents = mapImageToFile(
                params.details.itineraryParts[i].serviceData.travelInsuranceData.documents,
                partFiles
              );
          }
        }
        const url = `${baseURL}${endPoints.updateStatus}`;
        const data = yield call(postRequest, url, params, headers);
        if (!data.error) {
          yield put(updateStatusSuccess(data));
        } else {
          yield put(updateStatusFailure(data.message));
        }
      } else {
        yield put(updateStatusFailure('Error uploading images to server'));
      }
    }
  } catch (err) {
    yield put(updateStatusFailure(err.message));
  }
}

export function* createUpdateItinerarySaga() {
  yield takeLatest(CREATE_UPDATE_ITINERARY, createUpdateItinerary);
}

function* remarkUpdate(action) {
  try {
    const { params, headers } = action.payload;
    const endPoint = params.type === 'CREATE'
      ? endPoints.createRemark : `${endPoints.updateRemark}${params._id}`;
    const url = `${baseURL}${endPoint}`;
    const request = params.type === 'CREATE' ? postRequest : putRequest;
    const refData = {
      remarkType: params.type,
      updateIn: params.updateIn,
    };
    delete params.updateIn;
    delete params.type;
    const data = yield call(request, url, params, headers);
    if (data.state) {
      yield put(remarkUpdateSuccess({
        ...data,
        refData,
      }));
    } else {
      yield put(remarkUpdateFailure(data.message));
    }
  } catch (err) {
    yield put(remarkUpdateFailure(err.message));
  }
}

export function* remarkUpdateSaga() {
  yield takeEvery(REMARK_UPDATE_INIT, remarkUpdate);
}

function* getRequestFilters(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.requestFilters}`;
    delete params.starred;
    delete params.status;
    const data = yield call(postRequest, url, params, headers);
    if (!data.error) {
      yield put(getRequestFiltersSuccess(data));
    } else {
      yield put(getRequestFiltersFailure(data.message));
    }
  } catch (err) {
    yield put(getRequestFiltersFailure(err.message));
  }
}

export function* getRequestFiltersSaga() {
  yield takeLatest(GET_REQUEST_FILTERS_INIT, getRequestFilters);
}

function* starRequest(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.starRequest}`;
    const { searchUpdate } = params;
    delete params.searchUpdate;
    const data = yield call(postRequest, url, params, headers);
    if (!data.error) {
      yield put(starRequestSuccess({
        ...data,
        searchUpdate,
      }));
    } else {
      yield put(starRequestFailure(data.message));
    }
  } catch (err) {
    yield put(starRequestFailure(err.message));
  }
}

export function* starRequestSaga() {
  yield takeLatest(STAR_REQUEST_INIT, starRequest);
}

function* interestedRequest(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.interestedRequest}`;
    const { searchUpdate } = params;
    delete params.searchUpdate;
    const data = yield call(postRequest, url, params, headers);
    if (!data.error) {
      yield put(interestedRequestSuccess({
        ...data,
        searchUpdate,
      }));
    } else {
      yield put(interestedRequestFailure(data.message));
    }
  } catch (err) {
    yield put(interestedRequestFailure(err.message));
  }
}

export function* interestedRequestSaga() {
  yield takeLatest(INTERESTED_REQUEST_INIT, interestedRequest);
}

function* cancelItinerary(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.cancelItinerary}`;
    const { searchKey } = params;
    delete params.searchKey;
    const data = yield call(postRequest, url, params, headers);
    if (!data.error) {
      yield put(cancelItinerarySuccess({
        ...data,
        searchKey,
      }));
    } else {
      yield put(cancelItineraryFailure(data.message));
    }
  } catch (err) {
    yield put(cancelItineraryFailure(err.message));
  }
}

export function* cancelItinerarySaga() {
  yield takeLatest(CANCEL_ITINERARY_INIT, cancelItinerary);
}

function* updateOrder(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.orderModify}`;
    const data = yield call(postRequest, url, params, headers);
    if (!data.error) {
      yield put(updateOrderSuccess(data));
    } else {
      yield put(updateOrderFailure(data.message));
    }
  } catch (err) {
    yield put(updateOrderFailure(err.message));
  }
}

export function* updateOrderSaga() {
  yield takeLatest(UPDATE_ORDER_INIT, updateOrder);
}

function* modifyInstallments(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.modifyInstallments}`;
    const data = yield call(postRequest, url, params, headers);
    if (!data.error) {
      yield put(modifyInstallmentsSuccess(data));
    } else {
      yield put(modifyInstallmentsFailure(data.message));
    }
  } catch (err) {
    yield put(modifyInstallmentsFailure(err.message));
  }
}

export function* modifyInstallmentsSaga() {
  yield takeLatest(MODIFY_INSTALLMENTS_INIT, modifyInstallments);
}

function* bookingAction(action) {
  try {
    const { params: { bookingParams, type, requestId }, headers } = action.payload;
    const url = `${baseURL}${type === 'accept_booking' ? endPoints.acceptBooking : endPoints.confirmBooking}`;
    const data = yield call(postRequest, url, bookingParams, headers);
    if (!data.error) {
      yield put(bookingActionSuccess({
        ...data,
        requestId,
      }));
    } else {
      yield put(bookingActionFailure(data.message));
    }
  } catch (err) {
    yield put(bookingActionFailure(err.message));
  }
}

export function* bookingActionSaga() {
  yield takeLatest(BOOKING_ACTION_INIT, bookingAction);
}

function* acceptPayment(action) {
  try {
    const { params: { paymentData, refData }, headers } = action.payload;
    const { referenceDocuments } = paymentData;
    const uploadTemplate = {
      fileName: '',
      fileCategory: 'PAYMENT_PROOF',
      ttlMinutes: 2,
      extension: 'jpeg',
      ContentType: 'image/jpeg',
    };
    const documents = [];
    const docLinkParams = [];
    const fileNameMap = {};
    if (referenceDocuments) {
      for (let i = 0; i < referenceDocuments.length; i++) {
        const { extension, ContentType } = getFileExtension(referenceDocuments[i].name);
        fileNameMap[referenceDocuments[i].name] = referenceDocuments[i];
        docLinkParams.push({
          ...uploadTemplate,
          extension,
          ContentType,
          fileName: referenceDocuments[i].name,
        });
      }
    }
    const linkUrl = `${baseURL}${endPoints.uploadDocs}`;
    const refDocsUploadLinks = yield call(postRequest, linkUrl, { files: docLinkParams }, headers);
    if (!refDocsUploadLinks.error) {
      const uploadResp = yield all(refDocsUploadLinks.urls.map(({
        signedUrl, fileName, document,
      }) => {
        // const formData = new FormData();
        // formData.append('file', fileNameMap[fileName]);
        documents.push(document);
        return call(putRequest, signedUrl, fileNameMap[fileName], { 'Content-Type': 'multipart/form-data' });
      }));
      let state = true;
      for (let i = 0; i < uploadResp.length; i++) {
        if (uploadResp[i].state !== true) {
          state = false;
          break;
        }
      }
      if (state) {
        const url = `${baseURL}${endPoints.acceptPayment}`;
        const finalPaymentData = {
          ...paymentData,
          documents,
        };
        delete finalPaymentData.referenceDocuments;
        const data = yield call(postRequest, url, finalPaymentData, headers);
        if (!data.error) {
          yield put(acceptPaymentSuccess({
            ...data,
            refData,
          }));
        } else {
          yield put(acceptPaymentFailure(data.message));
        }
      } else {
        yield put(acceptPaymentFailure('Error uploading docs to server'));
      }
    } else {
      yield put(acceptPaymentFailure(refDocsUploadLinks.message || 'Error getting upload links'));
    }
  } catch (err) {
    yield put(acceptPaymentFailure(err.message));
  }
}

export function* acceptPaymentSaga() {
  yield takeLatest(ACCEPT_PAYMENT_INIT, acceptPayment);
}

function* getRequestByID(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.getRequestByID}${params.usId}`;
    const data = yield call(getRequest, url, {}, headers);
    if (!data.error) {
      yield put(getRequestByIDSuccess(data));
    } else {
      yield put(getRequestByIDFailure(data.message));
    }
  } catch (err) {
    yield put(getRequestByIDFailure(err.message));
  }
}

export function* getRequestByIDSaga() {
  yield takeLatest(GET_REQUEST_BY_ID_INIT, getRequestByID);
}

function* resendConfirmation(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.resendConfirmation}`;
    const data = yield call(postRequest, url, params, headers);
    if (!data.error) {
      yield put(resendConfirmationSuccess(data));
    } else {
      yield put(resendConfirmationFailure(data.message));
    }
  } catch (err) {
    yield put(resendConfirmationFailure(err.message));
  }
}

export function* resendConfirmationSaga() {
  yield takeLatest(RESEND_CONFIRMATION_INIT, resendConfirmation);
}

function* refundPayment(action) {
  const { params: { paymentData, refData, fromWallet }, headers } = action.payload;
  let successFunc = refundPaymentSuccess;
  let fiailFunc = refundPaymentFailure;
  if (fromWallet) {
    successFunc = refundFromWalletSuccess;
    fiailFunc = refundFromWalletFailure;
  }
  try {
    const { referenceDocuments } = paymentData;
    const uploadTemplate = {
      fileName: '',
      fileCategory: 'PAYMENT_PROOF',
      ttlMinutes: 2,
      extension: 'jpeg',
      ContentType: 'image/jpeg',
    };
    const documents = [];
    const docLinkParams = [];
    const fileNameMap = {};
    for (let i = 0; i < referenceDocuments.length; i++) {
      const { extension, ContentType } = getFileExtension(referenceDocuments[i].name);
      fileNameMap[referenceDocuments[i].name] = referenceDocuments[i];
      docLinkParams.push({
        ...uploadTemplate,
        extension,
        ContentType,
        fileName: referenceDocuments[i].name,
      });
    }
    const linkUrl = `${baseURL}${endPoints.uploadDocs}`;
    const refDocsUploadLinks = yield call(postRequest, linkUrl, { files: docLinkParams }, headers);
    if (!refDocsUploadLinks.error) {
      const uploadResp = yield all(refDocsUploadLinks.urls.map(({
        signedUrl, fileName, document,
      }) => {
        documents.push(document);
        return call(putRequest, signedUrl, fileNameMap[fileName], { 'Content-Type': 'multipart/form-data' });
      }));
      let state = true;
      for (let i = 0; i < uploadResp.length; i++) {
        if (uploadResp[i].state !== true) {
          state = false;
          break;
        }
      }
      if (state) {
        let url;
        if (fromWallet) {
          url = `${baseURL}${endPoints.refundFromWallet}`;
        } else {
          url = `${baseURL}${endPoints.manualRefund}`;
        }
        const finalPaymentData = {
          ...paymentData,
          documents,
        };
        delete finalPaymentData.referenceDocuments;
        const data = yield call(postRequest, url, finalPaymentData, headers);
        if (!data.error) {
          yield put(successFunc({
            ...data,
            refData,
          }));
        } else {
          yield put(fiailFunc(data.message));
        }
      } else {
        yield put(fiailFunc('Error uploading docs to server'));
      }
    } else {
      yield put(fiailFunc(refDocsUploadLinks.message || 'Error getting upload links'));
    }
  } catch (err) {
    yield put(fiailFunc(err.message));
  }
}

export function* refundPaymentSaga() {
  yield takeLatest(REFUND_PAYMENT_INIT, refundPayment);
}

export function* refundFromWalletSaga() {
  yield takeLatest(REFUND_FROM_WALLET_INIT, refundPayment);
}

function* refundToWallet(action) {
  try {
    const { params: { paymentData, refData }, headers } = action.payload;
    const url = `${baseURL}${endPoints.refundMoney}`;
    const data = yield call(postRequest, url, paymentData, headers);
    if (!data.error) {
      yield put(refundToWalletSuccess({ ...data, refData }));
    } else {
      yield put(refundToWalletFailure(data.message));
    }
  } catch (err) {
    yield put(refundToWalletFailure(err.message));
  }
}

export function* refundToWalletSaga() {
  yield takeLatest(REFUND_TO_WALLET_INIT, refundToWallet);
}

function* markBookingTransaction(action) {
  try {
    const { params, headers, requestInfo } = action.payload;
    const { invoices = [] } = params;
    const uploadTemplate = {
      fileName: '',
      fileCategory: 'SUPPLIER_INVOICE',
      ttlMinutes: 2,
      extension: 'jpeg',
      ContentType: 'image/jpeg',
    };
    const docLinkParams = [];
    const fileNameMap = {};
    for (let i = 0; i < invoices.length; i++) {
      const { extension, ContentType } = getFileExtension(invoices[i].name);
      fileNameMap[invoices[i].name] = invoices[i];
      docLinkParams.push({
        ...uploadTemplate,
        extension,
        ContentType,
        fileName: invoices[i].name,
      });
    }
    const linkUrl = `${baseURL}${endPoints.uploadDocs}`;
    const refDocsUploadLinks = yield call(postRequest, linkUrl, { files: docLinkParams }, headers);
    const documents = [];
    if (!refDocsUploadLinks.error) {
      const uploadResp = yield all(refDocsUploadLinks.urls.map(({
        signedUrl, fileName, document,
      }) => {
        documents.push(document);
        return call(putRequest, signedUrl, fileNameMap[fileName], { 'Content-Type': 'multipart/form-data' });
      }));
      let state = true;
      for (let i = 0; i < uploadResp.length; i++) {
        if (uploadResp[i].state !== true) {
          state = false;
          break;
        }
      }
      if (state) {
        const url = `${baseURL}${endPoints.bookingTransaction}`;
        params.invoices = documents;
        const data = yield call(postRequest, url, params, headers);
        if (!data.error) {
          yield put(markBookingTransactionSuccess(data));
          yield getBookings({
            payload: {
              params: requestInfo,
              headers,
            },
          });
        } else {
          yield put(markBookingTransactionFailure(data.message));
        }
      } else {
        yield put(markBookingTransactionFailure('Error uploading docs'));
      }
    } else {
      yield put(markBookingTransactionFailure('Error uploading docs'));
    }
  } catch (err) {
    yield put(markBookingTransactionFailure(err.message));
  }
}

export function* markBookingTransactionSaga() {
  yield takeLatest(MARK_BOOKING_TRANSACTION_INIT, markBookingTransaction);
}

function* cancelBooking(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.cancelBooking}`;
    const data = yield call(postRequest, url, params, headers);
    if (!data.error) {
      yield put(cancelBookingSuccess(data));
    } else {
      yield put(cancelBookingFailure(data.message));
    }
  } catch (err) {
    yield put(cancelBookingFailure(err.message));
  }
}

export function* cancelBookingSaga() {
  yield takeLatest(CANCEL_BOOKING_INIT, cancelBooking);
}

function* getBookings(action) {
  const { params, headers } = action.payload;
  try {
    const url = `${baseURL}${endPoints.getBookings}`;
    const data = yield call(getRequest, url, params, headers);
    if (!data.error) {
      yield put(getBookingsSuccess({ ...data, ...params }));
    } else {
      yield put(getBookingsFailure({ error: data.message, ...params }));
    }
  } catch (err) {
    yield put(getBookingsFailure({ error: err.message, ...params }));
  }
}

export function* getBookingsSaga() {
  yield takeLatest(GET_BOOKINGS_INIT, getBookings);
}

function* getB2bWalletBalance(action) {
  try {
    const { params, headers } = action.payload;
    const url = `${baseURL}${endPoints.b2bPartnerWallet}/${params.partnerId}`;
    const data = yield call(getRequest, url, {}, headers);
    if (!data.error) {
      yield put(getB2bWalletBalanceSuccess(data));
    } else {
      yield put(getB2bWalletBalanceFailure(data.message));
    }
  } catch (err) {
    yield put(getB2bWalletBalanceFailure(err.message));
  }
}

export function* getB2bWalletBalanceSaga() {
  yield takeLatest(GET_B2B_WALLET_BALANCE_INIT, getB2bWalletBalance);
}

function* getRefundData(action) {
  const { params, headers } = action.payload;
  try {
    const url = `${baseURL}${endPoints.orderRefundData}${params.orderId}`;
    const data = yield call(getRequest, url, {}, headers);
    if (!data.error) {
      yield put(getRefundDataSuccess(data));
    } else {
      yield put(getRefundDataFailure({ error: data.message, productId: params.productId }));
    }
  } catch (err) {
    yield put(getRefundDataFailure({ error: err.message, productId: params.productId }));
  }
}

export function* getRefundDataSaga() {
  yield takeLatest(GET_REFUND_DATA_INIT, getRefundData);
}

function* getVirtualAccount(action) {
  const { params, headers } = action.payload;
  try {
    const url = `${baseURL}${endPoints.virtualAccount}${params.requestId}`;
    const data = yield call(getRequest, url, {}, headers);
    if (!data.error) {
      yield put(getVirtualAccountDataSuccess({ ...data, requestId: params.requestId }));
    } else {
      yield put(getVirtualAccountDataFailure({ error: data.message, productId: params.productId }));
    }
  } catch (err) {
    yield put(getVirtualAccountDataFailure({ error: err.message, productId: params.productId }));
  }
}

export function* getVirtualAccountDataSaga() {
  yield takeLatest(GET_VIRTUAL_ACCOUNT_DATA_INIT, getVirtualAccount);
}
