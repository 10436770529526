import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import Radio from '@material-ui/core/Radio';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { withStyles } from '@material-ui/styles';
import Autocomplete from 'UI/autocomplete';
import invalid from 'assets/svg/invalid.svg';
import Header from 'UI/header';
import calendarImg from 'assets/svg/calendar.svg';

class InvalidRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: -1,
      reasons: {},
      currency: {
        value: 'INR',
        valid: true,
        item: {},
      },
      postponeDate: new Date().toISOString(),
      dateSelected: false,
      errorMsg: '',
    };
    this.monthEpoch = 2592000000;
    const { invalidReasons } = this.props;
    this.reasons = invalidReasons;
    this.follwUp = ['In a month', 'In 2 months', 'Choose date'];
  }

  reasonChange = (index) => {
    this.setState({ checked: index });
  };

  handleChange = (index, val) => {
    this.setState((prevState) => ({
      reasons: {
        ...prevState.reasons,
        [index]: val,
      },
      errorMsg: '',
    }));
  };

  handleSearchChange = (searchType, value) => {
    const { getSuggestions } = this.props;
    getSuggestions(searchType, value);
    this.setState((prevState) => ({ currency: { ...prevState.currency, value }, errorMsg: '' }));
  };

  handleSelection = (accessor, field, item) => {
    const modCurrency = {
      value: item[accessor],
      valid: true,
      item,
    };
    this.setState({ currency: modCurrency, errorMsg: '' });
  };

  follupReason = (reason) => {
    const { reasons } = this.state;
    const modReason = { ...reasons };
    if (reason !== 'Choose date') {
      modReason['4'] = reason;
      this.setState({ reasons: modReason, dateSelected: false });
    }
  };

  dateChangeHandler = (date) => {
    this.setState({ dateSelected: true, postponeDate: date });
  };

  submitHandler = () => {
    const { onSubmit } = this.props;
    const {
      currency,
      checked,
      reasons,
      postponeDate,
    } = this.state;
    if (checked === -1) {
      this.setState({ errorMsg: 'Select reason for marking invalid' });
      return;
    }
    const details = {};
    details.code = this.reasons[checked].code;
    details.value = reasons[checked] || this.reasons[checked].title;
    if (checked === 1) {
      details.value = {
        amount: reasons[checked],
        currency: currency.value,
      };
    } else if (checked === 4) {
      let date = Date.now();
      if (reasons['4'] === 'In a month') {
        date += this.monthEpoch;
        date = moment(date).toISOString(false);
      } else if (reasons['4'] === 'In 2 months') {
        date += 2 * this.monthEpoch;
        date = moment(date).toISOString(false);
      } else {
        date = moment(postponeDate).toISOString(false);
      }
      details.value = date;
    }
    onSubmit({ details });
  };

  render() {
    const {
      classes,
      onDismiss,
      suggestions,
    } = this.props;
    const {
      checked,
      reasons,
      postponeDate,
      dateSelected,
      currency,
      errorMsg,
    } = this.state;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.container}>
          <Header title="Invalid" img={invalid} onDismiss={onDismiss} />
          <div className={classes.body}>
            <Typography className={classes.description}>
              Select a reason for marking the customer invalid
            </Typography>
            <button type="button" className={classes.radioRow} onClick={() => this.reasonChange(0)}>
              <Radio
                checked={checked === 0}
                color="primary"
                className={classes.radio}
                onClick={() => this.reasonChange(0)}
              />
              <Typography className={clsx(classes.reason, checked === 0 && classes.selectedReason)}>
                {this.reasons[0].title}
              </Typography>
            </button>
            <button type="button" className={classes.radioRow} onClick={() => this.reasonChange(1)}>
              <Radio
                checked={checked === 1}
                color="primary"
                className={classes.radio}
                onClick={() => this.reasonChange(1)}
              />
              <Typography className={clsx(classes.reason, checked === 1 && classes.selectedReason)}>
                {this.reasons[1].title}
              </Typography>
            </button>
            {checked === 1 ? (
              <div className={classes.budgetSection}>
                <div className={classes.budgetWrapper}>
                  <Autocomplete
                    data={suggestions.currencies}
                    value={currency.value}
                    accessor="code"
                    variant="no-outline"
                    popupClass={classes.popupClass}
                    onChange={(val) => this.handleSearchChange('currencies', val)}
                    onSelected={(item) => this.handleSelection('code', 'currencyCode', item)}
                  />
                </div>
                <InputBase
                  autoFocus
                  placeholder="Customer's budget ?"
                  value={reasons['1']}
                  type="number"
                  onChange={(e) => this.handleChange('1', e.target.value)}
                  classes={{
                    root: classes.inputArea,
                  }}
                />
              </div>
            ) : null}
            <button type="button" className={classes.radioRow} onClick={() => this.reasonChange(2)}>
              <Radio
                checked={checked === 2}
                color="primary"
                className={classes.radio}
                onClick={() => this.reasonChange(0)}
              />
              <Typography className={clsx(classes.reason, checked === 2 && classes.selectedReason)}>
                {this.reasons[2].title}
              </Typography>
            </button>
            <button type="button" className={classes.radioRow} onClick={() => this.reasonChange(3)}>
              <Radio
                checked={checked === 3}
                color="primary"
                className={classes.radio}
                onClick={() => this.reasonChange(0)}
              />
              <Typography className={clsx(classes.reason, checked === 3 && classes.selectedReason)}>
                {this.reasons[3].title}
              </Typography>
            </button>
            <button type="button" className={classes.radioRow} onClick={() => this.reasonChange(4)}>
              <Radio
                checked={checked === 4}
                color="primary"
                className={classes.radio}
                onClick={() => this.reasonChange(2)}
              />
              <Typography className={clsx(classes.reason, checked === 4 && classes.selectedReason)}>
                {this.reasons[4].title}
              </Typography>
            </button>
            {checked === 4 ? (
              <div className={classes.postponed}>
                <Typography className={classes.followText}>Follow up</Typography>
                <div className={classes.followRow}>
                  {this.follwUp.map((follow, i) => {
                    const disp = dateSelected ? moment(postponeDate).format('DD MMM YY') : follow;
                    let selected = follow === reasons['4'] && checked === 4 ? i : -1;
                    if (checked === 4 && dateSelected) {
                      selected = 2;
                    }
                    if (i === 2) {
                      return (
                        <ButtonBase
                          type="button"
                          key={follow}
                          className={clsx(
                            classes.followChip,
                            selected === i && classes.selectedChip
                          )}
                          onClick={() => this.follupReason(follow)}
                        >
                          <DatePicker
                            disablePast
                            value={postponeDate}
                            className={classes.followupDate}
                            onChange={this.dateChangeHandler}
                          />
                          {disp}
                          <img src={calendarImg} alt="[]" className={classes.calendarImg} />
                        </ButtonBase>
                      );
                    }
                    return (
                      <ButtonBase
                        type="button"
                        key={follow}
                        className={clsx(classes.followChip, selected === i && classes.selectedChip)}
                        onClick={() => this.follupReason(follow)}
                      >
                        {follow}
                      </ButtonBase>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <button type="button" className={classes.radioRow} onClick={() => this.reasonChange(5)}>
              <Radio
                checked={checked === 5}
                color="primary"
                className={classes.radio}
                onClick={() => this.reasonChange(5)}
              />
              <Typography className={clsx(classes.reason, checked === 5 && classes.selectedReason)}>
                {this.reasons[5].title}
              </Typography>
            </button>
            <button type="button" className={classes.radioRow} onClick={() => this.reasonChange(6)}>
              <Radio
                checked={checked === 6}
                color="primary"
                className={classes.radio}
                onClick={() => this.reasonChange(6)}
              />
              <Typography className={clsx(classes.reason, checked === 6 && classes.selectedReason)}>
                {this.reasons[6].title}
              </Typography>
            </button>
            <button type="button" className={classes.radioRow} onClick={() => this.reasonChange(7)}>
              <Radio
                checked={checked === 7}
                color="primary"
                className={classes.radio}
                onClick={() => this.reasonChange(7)}
              />
              <Typography className={clsx(classes.reason, checked === 7 && classes.selectedReason)}>
                {this.reasons[7].title}
              </Typography>
            </button>
            {checked === 7 ? (
              <div className={classes.reasonSection}>
                <InputBase
                  multiline
                  autoFocus
                  rows={3}
                  rowsMax={4}
                  value={reasons['7']}
                  onChange={(e) => this.handleChange('7', e.target.value)}
                  placeholder="Mention the reason"
                  classes={{
                    multiline: classes.inputAreaR,
                    focused: classes.focusedInput,
                  }}
                />
              </div>
            ) : null}
          </div>
          <div className={classes.footer}>
            {errorMsg ? (
              <Typography className={classes.errorMsg}>{errorMsg}</Typography>
            ) : null}
            <Button className={classes.submitButton} onClick={this.submitHandler}>
              Submit
            </Button>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    borderRadius: 4,
    backgroundColor: theme.colors.white,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px 40px',
    borderBottom: `1px solid ${theme.colors.border}`,
  },
  title: {
    color: theme.colors.textDark,
    fontWeight: 'bold',
    fontSize: 18,
    paddingLeft: 10,
  },
  closeButton: {
    marginLeft: 'auto',
  },
  body: {
    padding: '20px 40px',
  },
  description: {
    fontSize: 18,
    color: theme.colors.black,
    margin: '0 0 10px 6px',
  },
  radioRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    outline: 'none',
    border: 'none',
    height: 32,
    cursor: 'pointer',
    backgroundColor: 'inherit',
  },
  radio: {
    padding: 4,
  },
  reason: {
    marginLeft: 6,
    color: theme.colors.textLight,
    fontSize: 16,
  },
  selectedReason: {
    color: theme.colors.black,
  },
  autoComplete: {
    width: '70%',
    marginLeft: 44,
    marginBottom: 10,
  },
  reasonSection: {
    marginLeft: 44,
  },
  inputArea: {
    width: '100%',
    padding: '0 10px',
  },
  inputAreaR: {
    borderRadius: 4,
    padding: '6px',
    border: `1px solid ${theme.colors.border}`,
    width: '100%',
  },
  popupClass: {
    width: 200,
    top: 32,
    left: -20,
  },
  focusedInput: {
    border: `1px solid ${theme.colors.primary}`,
  },
  footer: {
    padding: '15px 40px 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  submitButton: {
    width: 160,
    borderRadius: 25,
    padding: '5px 10px',
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    fontSize: 18,
    letterSpacing: 0.5,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  icon: {
    width: 30,
    height: 30,
  },
  closeIcon: {
    width: 14,
    height: 14,
  },
  followText: {
    fontSize: 14,
    color: theme.colors.textLight,
    marginBottom: 8,
  },
  postponed: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `2px solid ${theme.colors.border}`,
    paddingLeft: 20,
    marginLeft: 44,
  },
  followRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  followChip: {
    outline: 'none',
    border: `1px solid ${theme.colors.border}`,
    color: theme.colors.black,
    fontSize: 14,
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    borderRadius: 15,
    padding: '5px 10px',
    minWidth: 90,
    marginRight: 10,
    position: 'relative',
    fontFamily: 'Lato',
  },
  selectedChip: {
    border: `1px solid ${theme.colors.primary}`,
  },
  calendarImg: {
    height: 16,
    marginLeft: 10,
  },
  followupDate: {
    opacity: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'pointer',
  },
  budgetSection: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 4,
    height: 40,
    border: `1px solid ${theme.colors.border}`,
    marginLeft: 44,
    marginBottom: 15,
    width: '70%',
  },
  budgetWrapper: {
    alignSelf: 'center',
    width: 100,
    padding: '10px 5px 10px 15px',
    marginRight: 4,
    borderRight: `1px solid ${theme.colors.border}`,
  },
});

InvalidRequest.propTypes = {
  classes: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  invalidReasons: PropTypes.array.isRequired,
  suggestions: PropTypes.object,
};

export default withStyles(styles)(InvalidRequest);
