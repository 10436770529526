import create from 'assets/svg/create.svg';
import unreachable from 'assets/svg/unreachable.svg';
import invalid from 'assets/svg/invalid_person.svg';
import followUp from 'assets/svg/follow_up.svg';
import send from 'assets/svg/send.svg';
import upload from 'assets/svg/upload.svg';
import lost from 'assets/svg/lost.svg';
import acceptPayment from 'assets/svg/accept_payment.svg';
import request from 'assets/svg/request.svg';
import tripDeferred from 'assets/svg/trip_deferred.svg';
import filter from 'assets/svg/filter.svg';
import placeholderImg from 'assets/png/placeholder.png';
import renewImg from 'assets/svg/refresh.svg';

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_ENV === 'production'
  ? '551169980759-3vco2uhtqdquo21c2vcimu3h0nat5thr' : '551169980759-ahh9adh07cnh1g3itp6akv4qrgds6p6k';

export const GOOGLE_MAPS_API = process.env.REACT_APP_ENV === 'production'
  ? '' : 'AIzaSyA4FRmn0UXfl0evh6Q7sZ9RGxn6UYiQwug';

export const IMAGE_PLACEHOLDER = placeholderImg;

export const STATUS_IMGS = {
  add_requirement: create,
  create_trip: create,
  unreachable,
  follow_up: followUp,
  mark_invalid: invalid,
  mark_unreachable: invalid,
  send_itinerary: send,
  mark_lost: lost,
  undefer: renewImg,
  send_payment_link: lost,
  request_payment: lost,
  upload_docs: upload,
  reassign: lost,
  trip_deferred: tripDeferred,
  accept_payment: acceptPayment,
  qualify_request: filter,
  revive: renewImg,
  send_to_request_bank: renewImg,
  request_quotation: request,
  re_sync: renewImg,
};

export const DAY_MAP = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const MONTH_MAP = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export const SUPPLIER_TYPES = {
  STAY: 'STAY',
  EXPERIENCE: 'EXPERIENCE',
  LAND_TRANSFER: 'LAND_TRANSFER',
  AIR_TRANSFER: 'AIR_TRANSFER',
  WATER_TRANSFER: 'WATER_TRANSFER',
  VISA: 'VISA',
  FOREX: 'FOREX',
  INSURANCE: 'INSURANCE',
};

export const ROLES = {
  ACCOUNTS: 'ACCOUNTS',
  EXPERT: 'EXPERT',
  FILTRATION: 'FILTRATION',
  OPERATION: 'OPERATION',
  TEAM_LEAD: 'TEAM_LEAD',
  FILTRATION_TEAM_LEAD: 'FILTRATION_TEAM_LEAD',
  FELLOW: 'FELLOW',
  ADMIN: 'ADMIN',
  // STAY_EDIT: 'STAY_EDIT',
  INVENTORY_CRUD: 'INVENTORY_CRUD',
  TECH: 'TECH',
  PAYMENT: 'PAYMENT',
  PAYOUT_ADMIN: 'PAYOUT_ADMIN',
  FLIGHT: 'FLIGHT',
  MARKETING_MANAGER: 'MARKETING_MANAGER',
};

export const REMOVING_REQUEST_STATUS = {
  LOST: true,
  INVALID: true,
  ITINERARY_SENT: true,
};

export const ITINERARY_ACTIONS_TYPES = {
  VIEW_BOOKING: 'view_booking',
  ACCEPT_BOOKING: 'accept_booking',
  CONFIRM_BOOKING: 'confirm_booking',
  VIEW_ITINERARY: 'view_itinerary',
  MODIFY_ITINERARY: 'modify_itinerary',
  MODIFY_ITINERARY_TECH: 'modify_itinerary_tech',
  // CREATE_OPS_ITINERARY: 'create_ops_itinerary',
  APPROVE_REJECT_ITINERARY: 'approve_reject_itinerary',
  EDIT: 'edit',
  REQUEST_BOOKING: 'request_booking',
  // QUOTE: 'quote',
  // GENERATE_VOUCHER: 'generate_voucher',
};

export const ITINERARY_READ_MODES = {
  view_booking: true,
  accept_booking: true,
  confirm_booking: true,
  view_itinerary: true,
  approve_reject_itinerary: true,
};

export const CHANGE_TYPES = {
  ADD: 'ADD',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  NO_CHANGE: 'NO_CHANGE',
};

export const BLOCK_TYPES = {
  TEMPORARY: 'TEMPORARY',
  PERMANENT: 'PERMANENT',
  UNBLOCKED: 'UNBLOCKED',
};

export const TIMEOUT = 21000;
